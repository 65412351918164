import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormControl, Grid } from '@mui/material';

import Toolbar from '@mui/material/Toolbar';

import { ReactComponent as CalendarIcon } from '../icons/calendar-blue.svg';
import { ButtonGroup, MenuItem, ListItemIcon, ListItemText, Select, SelectChangeEvent } from '@mui/material';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { PublicationSelector } from './PublicationSelector';
import { Heading } from './common/Heading';
import { styled } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#3355D1',
    },
  },
});

const ListItemWithIcon = styled(ListItemIcon)`
  display: flex;
  align-items: center;
`;

function ToolBarImageSwap({ setPublisher, publishers, selectedPublisher, title = 'Video Swap' }) {
  const dispatch = useDispatch();
  // const { isGroupByKeyword } = useSelector((state: any) => state.imageSwap);
  const { formatFilter } = useSelector((state: any) => state.imageSwap);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch({ type: 'SET_FILTER_ARTICLES', payload: event.target.value });
  };

  const handleFormatChange = (event: SelectChangeEvent) => {
    dispatch({ type: 'SET_FILTER_FORMAT', payload: event.target.value });
  };

  return (
    <ThemeProvider theme={theme}>
      <Toolbar style={{ paddingBottom: '45px', paddingLeft: '15px' }}>
        <Grid item xs={10} sx={{ flex: 1 }}>
          <Grid container alignItems={'center'} spacing={2}>
            <Grid item>
              <Heading>{title}</Heading>
            </Grid>
            <Grid item>
              <Grid container alignItems={'center'}>
                <ButtonGroup>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{
                      marginTop: '0px',
                      marginBottom: '0px',
                    }}
                  >
                    <Select
                      id="demo-simple-select"
                      defaultValue={'7days'}
                      onChange={handleChange}
                      sx={{
                        fontFamily: 'ProximaNovaBold',
                        textTransform: 'none',
                        color: '#000',
                        height: '43px',
                        width: '200px',
                        marginRight: '30px',
                      }}
                    >
                      <MenuItem value="1day">
                        <ListItemWithIcon>
                          <CalendarIcon />
                          <ListItemText
                            primary="Dates"
                            style={{
                              paddingLeft: '10px',
                            }}
                          />
                        </ListItemWithIcon>
                      </MenuItem>
                      <MenuItem value={'1day'}>last 24 hours</MenuItem>
                      <MenuItem value={'yesterday'}>yesterday</MenuItem>
                      <MenuItem value={'7days'}>previous 7 days</MenuItem>
                      <MenuItem value={'30days'}>previous 30 days</MenuItem>
                    </Select>
                  </FormControl>
                </ButtonGroup>
                {/* <ButtonGroup
                  variant="contained"
                  disableElevation
                  color="primary"
                  sx={{ marginRight: '30px', height: '43px' }}
                >
                  <Button
                    sx={{
                      textTransform: 'none',
                      border: '1px #e0e0e0 solid',
                    }}
                    color={!isGroupByKeyword ? 'secondary' : 'primary'}
                    onClick={() => dispatch({ type: 'SET_GROUP_BY_KEYWORD', payload: false })}
                    startIcon={<ListIcon2 />}
                  >
                    <Typography variant="body1" style={{ fontFamily: 'ProximaNovaBold' }}>
                      List
                    </Typography>
                  </Button>
                  <Button
                    sx={{
                      textTransform: 'none',
                      border: '1px #e0e0e0 solid',
                    }}
                    color={isGroupByKeyword ? 'secondary' : 'primary'}
                    onClick={() => dispatch({ type: 'SET_GROUP_BY_KEYWORD', payload: true })}
                    startIcon={<Cluster />}
                  >
                    <Typography variant="body1" style={{ fontFamily: 'ProximaNovaBold' }}>
                      Top Stories
                    </Typography>
                  </Button>
                </ButtonGroup> */}
                {/* <ButtonGroup>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{
                      marginTop: '0px',
                      marginBottom: '0px',
                    }}
                  >
                    <Select
                      id="demo-simple-select"
                      defaultValue={'Filter'}
                      onChange={handleChange}
                      sx={{
                        fontFamily: 'ProximaNovaBold',
                        textTransform: 'none',
                        color: '#000',
                        height: '43px',
                        width: '130px',
                        marginRight: '30px',
                      }}
                    >
                      <MenuItem value="Filter">
                        <ListItemIcon>
                          <Filter
                            style={{
                              marginTop: '7px',
                            }}
                          />
                          <ListItemText
                            primary="Filter"
                            style={{
                              paddingLeft: '10px',
                            }}
                          />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem value={'section'}>section</MenuItem>
                    </Select>
                  </FormControl>
                </ButtonGroup> */}
              </Grid>
            </Grid>
            <Grid item>
              <Heading>Format</Heading>
            </Grid>
            <Grid item>
              <ButtonGroup>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  style={{
                    marginTop: '0px',
                    marginBottom: '0px',
                  }}
                >
                  <Select
                    id="format-select"
                    value={formatFilter}
                    onChange={handleFormatChange}
                    sx={{
                      fontFamily: 'ProximaNovaBold',
                      textTransform: 'none',
                      color: '#000',
                      height: '43px',
                      width: '200px',
                      marginRight: '30px',
                    }}
                  >
                    {['horizontal', 'vertical'].map((format: string) => (
                      <MenuItem value={format}>{format}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <PublicationSelector
            selectedPublication={selectedPublisher}
            publishers={publishers}
            onChange={setPublisher}
          />
        </Grid>
      </Toolbar>
    </ThemeProvider>
  );
}

export default styled(ToolBarImageSwap)`
  margin-bottom: 40px;
`;
