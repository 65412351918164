import playButton from '../../icons/play.png';
import * as React from 'react';
import { styled } from '@mui/material/styles';

const Root = styled('div')`
  position: relative;
  > video {
    position: absolute;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  > div {
    transition: opacity 0.2s;
  }

  &.preview-mode {
    > video {
      opacity: 1;
    }
    > div {
      opacity: 0;
    }
  }
`;

const CountVideo = styled('div')`
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #ff512d;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: 'ProximaNovaBold';
`;

export const VideoThumbnail = ({ imageUrl, title = '', videoCount, width, preview, previewVideoUrl, format }) => {
  const adjustedWidth = format === 'horizontal' ? width : width * 0.66;
  const height = format === 'horizontal' ? adjustedWidth * 0.63 : adjustedWidth / 0.63;
  return (
    <Root sx={{ adjustedWidth }} className={preview ? 'container preview-mode' : 'container'}>
      {previewVideoUrl && (
        <video width={adjustedWidth} height={height} controls={false} loop muted autoPlay>
          <source src={previewVideoUrl} type="video/mp4" />
        </video>
      )}
      <div
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: adjustedWidth,
          height: height,
          borderRadius: '5%',
        }}
      />
      {videoCount && videoCount > 1 ? <CountVideo>{videoCount}</CountVideo> : ''}
      <div title={title} className="play-icon">
        <img width={adjustedWidth} src={playButton} alt="play button" />
      </div>
    </Root>
  );
};

VideoThumbnail.defaultProps = {
  width: 90,
  preview: false,
  previewVideoUrl: null,
  format: 'horizontal',
};
