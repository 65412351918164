import TableHead from '@mui/material/TableHead';
import { TableCellBody, TableCellHead, TableRow } from './common/Table';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import { BoldTypography, BoldTypographyLarge } from './common/TextLabel';
import Moment from 'react-moment';
import { CircularProgress, Grid } from '@mui/material';
import { MatchType } from './common/MatchType';
import { VideoThumbnail } from './common/VideoThumbnail';
import { ReactComponent as BrandSafeIcon } from '../icons/brand_safe.svg';
import plusButton from '../icons/plus.png';
import Table from '@mui/material/Table';
import * as React from 'react';
import { Link } from './common/Link';
import { urlAppendQs } from '../utils/common';
import { demoPlayerId } from '../constant/player';
import { decode } from 'he';
import { ReactComponent as GainingIcon } from '../icons/gaining-article.svg';
import ProducerHeading from './ProducerHeading';

const capitalize = str => {
  if (/^u(k|s)$/.test(str.toLowerCase())) return str.toUpperCase();
  return str;
};

const optimiseThumbnail = (publisher, url) => {
  try {
    const newUrl = new URL(url);
    switch (publisher) {
      case 'newschain':
      case 'derbyshiretimes':
        newUrl.searchParams.set('width', '100');
        return newUrl.toString();
      case 'independent':
      case 'standard':
        newUrl.searchParams.set('width', '100');
        return newUrl.toString().replace(/s3.eu-west-1.amazonaws.com\/static/, 'static');
      default:
        return newUrl.toString();
    }
  } catch (ex) {
    return '/bg-grey.png';
  }
};

const getSectionFromUrl = url => {
  try {
    const { pathname } = new URL(url);
    return pathname.split('/').filter(Boolean).slice(0, -1).slice(0, 2).map(capitalize).join(', ');
  } catch (ex) {
    return '-';
  }
};

export const ImageSwapArticleTable = ({
  onSortByTraffic,
  onSortByDate,
  articles,
  onSortByScore,
  orderBy,
  orderDirection,
  onSetArticleForPreview,
  loadingId,
  articleDemo,
  autopublish,
  selectedPublisher,
  format = 'horizontal',
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCellHead style={{ cursor: 'pointer' }} onClick={onSortByTraffic}>
            <TableSortLabel
              active={orderBy === 'traffic'}
              direction={orderDirection || 'asc'}
              style={{ whiteSpace: 'nowrap' }}
            >
              15 MIN
            </TableSortLabel>
          </TableCellHead>
          <TableCellHead>THUMBNAIL</TableCellHead>
          <TableCellHead style={{ cursor: 'pointer' }} onClick={onSortByDate}>
            <TableSortLabel active={orderBy === 'date'} direction={orderDirection || 'asc'}>
              DATE
            </TableSortLabel>
          </TableCellHead>
          <TableCellHead>ARTICLE TITLE</TableCellHead>
          <TableCellHead>SECTION</TableCellHead>
          <TableCellHead style={{ cursor: 'pointer' }} onClick={onSortByScore}>
            <TableSortLabel active={orderBy === 'score'} direction={orderDirection || 'asc'}>
              MATCH
            </TableSortLabel>
          </TableCellHead>
          <TableCellHead>VIDEO</TableCellHead>
          {!articleDemo && <TableCellHead></TableCellHead>}
          <TableCellHead width="20%">PRODUCER</TableCellHead>
          <TableCellHead width="160">TOOLS</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {articles.length === 0 && (
          <TableRow>
            <TableCellBody colSpan={10} width={'100%'}>
              <div style={{ textAlign: 'center', padding: 20 }}>No matching article found.</div>
            </TableCellBody>
          </TableRow>
        )}
        {articles &&
          articles.map(row => (
            <TableRow
              sx={{
                height: '60px',
              }}
              key={row.id}
            >
              <TableCellBody title={`Total traffic count: ${row.page_view_count.toLocaleString()}`}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  {row.traffic_15_min_count.toLocaleString()}{' '}
                  {row.is_trending ? <GainingIcon style={{ verticalAlign: 'bottom' }} /> : ''}
                </Grid>
              </TableCellBody>
              <TableCellBody sx={{ minWidth: '90px' }}>
                <div
                  style={{
                    backgroundImage: `url(${optimiseThumbnail(selectedPublisher, row.thumbnail)})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '57px',
                    borderRadius: '5%',
                  }}
                ></div>
              </TableCellBody>
              <TableCellBody>
                <BoldTypography>
                  <Moment format="Do MMMM">{row.publish_date}</Moment>
                </BoldTypography>
              </TableCellBody>
              <TableCellBody sx={{ minWidth: '287px' }}>
                <Link
                  className="hover-underline"
                  href={row.article_url}
                  target="_blank"
                  variant="body1"
                  style={{
                    color: 'black',
                    fontFamily: 'ProximaNovaLight',
                    fontSize: 14,
                    textDecoration: 'none',
                  }}
                >
                  {decode(row.article_title)}
                </Link>
              </TableCellBody>
              <TableCellBody sx={{ minWidth: '117px' }}>
                <BoldTypographyLarge sx={{ textTransform: 'capitalize' }}>
                  {getSectionFromUrl(row.article_url)}
                </BoldTypographyLarge>
              </TableCellBody>
              <TableCellBody sx={{ minWidth: '120px' }}>
                <MatchType data={row} />
              </TableCellBody>
              <TableCellBody
                sx={{ minWidth: '90px' }}
                onClick={() => {
                  if (row.videos.length) {
                    onSetArticleForPreview(row);
                  }
                }}
              >
                {row.videos.length > 0 && (
                  <VideoThumbnail
                    imageUrl={row.video_thumbnail}
                    title={row.video_title}
                    videoCount={row.videos.length}
                    format={format}
                  />
                )}
              </TableCellBody>
              {!articleDemo && (
                <TableCellBody>
                  <BrandSafeIcon />
                </TableCellBody>
              )}
              <TableCellBody sx={{ minWidth: '150px' }}>
                <ProducerHeading row={row} />
              </TableCellBody>
              <TableCellBody sx={{ minWidth: '200px' }}>
                {row.id === loadingId && <CircularProgress />}
                {!row.has_been_swapped && row.id && row.videos.length > 0 && !loadingId && !articleDemo && (
                  <span onClick={() => onSetArticleForPreview(row)} style={{ cursor: 'pointer' }}>
                    <img width="28" src={plusButton} alt="plus button" />
                  </span>
                )}
                {articleDemo && row.videos.length > 0 && (
                  <Link
                    href={urlAppendQs(row.article_url, {
                      pixels_inject_media_id: row.videos[0].mediaid,
                      pixels_inject_player_id: demoPlayerId,
                      pixels_inject_tag: row.imageSelector,
                    })}
                    target={'_blank'}
                    style={{ cursor: 'pointer' }}
                  >
                    <img width="28" src={plusButton} alt="plus button" />
                  </Link>
                )}
                {/* Enable when there is action for this.. */}
                {/*&nbsp;&nbsp;&nbsp;&nbsp;*/}
                {/*<SvgIcon>*/}
                {/*  <PencilIcon/>*/}
                {/*</SvgIcon>*/}
              </TableCellBody>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
