import TableHead from '@mui/material/TableHead';
import { TableCellBody, TableCellHead, TableRow } from './common/Table';
import TableBody from '@mui/material/TableBody';
import { BoldTypography } from './common/TextLabel';
import Moment from 'react-moment';
import Table from '@mui/material/Table';
import { decode } from 'he';
import { ReactComponent as CopyContentIcon } from '../icons/copy-content.svg';
import { SvgIcon } from '@mui/material';

export const VideoLibraryTable = ({ videos, onSetVideoPreview }) => {
  const copyToClipboard = (mediaId: string) => () => {
    const textArea = document.createElement('textarea');
    textArea.value = mediaId;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCellHead>VIDEO</TableCellHead>
          <TableCellHead>TITLE</TableCellHead>
          <TableCellHead>DATE</TableCellHead>
          <TableCellHead>SUMMARY</TableCellHead>
          <TableCellHead>IAB CATEGORY</TableCellHead>
          <TableCellHead>DURATION</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {videos.length === 0 && (
          <TableRow>
            <TableCellBody colSpan={6} width={'100%'}>
              <div style={{ textAlign: 'center', padding: 20 }}>There are no videos yet</div>
            </TableCellBody>
          </TableRow>
        )}
        {videos &&
          videos.map(row => (
            <TableRow
              sx={{
                height: '60px',
              }}
              key={row.media_id}
            >
              <TableCellBody
                sx={{ width: row.format === 'vertical' ? '104px' : '160px' }}
                onClick={() => {
                  onSetVideoPreview(row);
                }}
              >
                {row.format === 'vertical' && (
                  <div
                    style={{
                      backgroundImage: `url(${row.thumbnail || '/bg-grey.png'})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '128px',
                      borderRadius: '5%',
                    }}
                  ></div>
                )}
                {row.format === 'horizontal' && (
                  <div
                    style={{
                      backgroundImage: `url(${row.thumbnail || '/bg-grey.png'})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '72px',
                      borderRadius: '5%',
                    }}
                  ></div>
                )}
              </TableCellBody>
              <TableCellBody sx={{ minWidth: '287px' }}>{decode(row.title)}
                <div title={row.media_id} style={{ cursor: 'pointer', marginTop: '10px' }}>
                  <SvgIcon onClick={copyToClipboard(row.media_id)}><CopyContentIcon /></SvgIcon>
                </div>
              </TableCellBody>
              <TableCellBody>
                <BoldTypography>
                  <Moment format="Do MMMM">{row.publish_date}</Moment>
                </BoldTypography>
              </TableCellBody>
              <TableCellBody>
                <BoldTypography>{row.summary}</BoldTypography>
              </TableCellBody>
              <TableCellBody>
                <BoldTypography>{row.iab_topics.map(({ text, score }) => <span key={text}
                                                                               title={score}
                                                                               style={{ display: 'block' }}>{text} ({(score * 100).toFixed(0)}%)</span>)}</BoldTypography>
              </TableCellBody>
              <TableCellBody>{row.duration}</TableCellBody>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
